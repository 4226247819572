.Grid-cell.u-xs-sizeFull.u-sizeFit {
    select {
        height: 40px;
        width: 130px !important;
        outline: none;
        padding: 0;
        text-align: left;
        background-color: #ffffff;
        font-size: 16px;
        color: black;
        border: none;
        border-bottom: 1px solid black;
    }
}

.estate-search-filter {
    position: relative;
    width: 130px;

    &__trigger {
        width: 100%;
        height: 40px;
        padding: 0;
        text-align: left;
        background-color: #ffffff;
        font-size: 16px;
        color: grey;
        border-bottom: 1px solid black;
    }

    &__icon {
        position: absolute;
        top: 6px;
        right: 11px;
        width: 12px;
        display: block;
        pointer-events: none;

        svg {
            width: 18px;

            * {
                fill: grey !important;
            }
        }
    }

    &__content {
        position: absolute;
        left: 0;
        z-index: 99;
        display: none;
        width: max-content;
        min-width: 130px;
        max-height: calc(8 * 1.8rem + 7 * 0.8rem + 2em);
        overflow: auto;
        background-color: #ffffff;
        border: 1px grey solid;
        border-top: none;
    }

    &.is-open {
        .estate-search-filter__content {
            display: block;
        }
    }
}

.ais-SearchBox {
    position: relative;

    &__container {
        display: flex;
    }

    &-input {
        appearance: none;

        height: 4.4rem;
        flex: 1 0;

        padding: 0 1em;

        background-color: #ffffff;

        border: none;

        &:hover,
        &:focus {
        }
    }

    &-submit {
        margin-left: 10px;
    }
}

.ais-RefinementList {
    &-list {
        list-style: none;
        padding: 5px;
        margin: 0;
    }

    &-item + &-item {
        margin-top: 0.8rem;
    }

    &-label {
        display: flex;
        flex-direction: row;

        align-items: center;

        line-height: 1;
    }

    &-checkbox {
        // empty
    }

    &-labelText {
        margin-left: 5px;
        font-size: 16px;
    }
}
