.Estate-leftlabels {
    position: absolute;
    top: 8px;
    left: 8px;
    width: fit-content;
    grid-column: 1/2;
    grid-row: 1/2;
}

.Estate-typeLabel {
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
}

.Estate-newLabel {
    color: #FFF;
    font-size: .77778rem;
    padding: .22222rem .44444rem;
    background-color: #EC008C;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    top: 0;
    left: -8px;
}
