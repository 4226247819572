.google-map {
    position: relative !important;

    display: block;
    width: 100%;
    height: 60vw;

    min-height: 30rem;
    max-height: 60rem;

    > div {
        position: absolute !important;
        left: 0;
        top: 0;

        display: block;
        width: 100%;
        height: 100%;
    }
}

.google-map-office-marker {
    display: block;
    width: 7.2rem;

    transform: translate(-70%, -90%);
}
