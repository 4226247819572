.Side.Side--right.mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .Side.Side--right {
        display: none;
    }

    .Side.Side--right.mobile {
        display: block;
    }
}
